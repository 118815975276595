class Whatsapp{
    constructor(listaNumerosTelefone){
        this.listaNumerosTelefone = listaNumerosTelefone
		this.telefone;

        this._render();
    }

    static isDicionario(t){
        return !("object" != typeof t || null === t || t instanceof Array || t instanceof Date)
    }

	send(telefone = '', email = '', nome = '', telefoneImobiliaria){

		if(nome == ''){
			console.error('Ops, informe seu nome.');
			return;
		}

		Boss.dialog.close();

		telefoneImobiliaria = '55' + telefoneImobiliaria;

		let text_interna = '';

		let pathname = window.location.pathname.split('/');
		
		let leads_param = {
			pes_nome: nome,
			pes_email: email,
			pes_celular: telefone,
		};

		if(pathname[2] == 'ver' && pathname[1] == 'imoveis'){
			text_interna = encodeURI(`&text=Olá, gostaria de mais informações sobre o imóvel ID: *${pathname[3]}* 
${window.location.href}`);

			leads_param['imo_codigo'] = pathname[3];
		}
		
		ajaxAsync('/contato/lead', leads_param);

		// Abrir o Whats em uma nova aba.
		width < 1023 ? window.open("https://api.whatsapp.com/send?phone=" + telefoneImobiliaria + text_interna, "whatsapp") : window.open("https://web.whatsapp.com/send?phone=" + telefoneImobiliaria + text_interna, "whatsapp");
	}

	static bossValidate(){
		Boss.validate.init({
			'config': {
				'formId': 'form-contato-whatsapp',
				'okMessage': 'Enviando...',
				'errorMessage': ' Tivemos um problema para enviar, tente novamente em alguns minutos.',
				'lockPushState': false
			},
			'fields': {
				'whatsapp_nome': {
					'rules': {
						'empty': {'error': 'Informe seu nome completo'}
					},
					'classError': 'error-field',
				},
				'whatsapp_email': {
					'rules': {
						'empty': {'error': 'Informe seu email completo'},
						'email': {'error': 'O e-mail informado é inválido!'}
					},
					'classError': 'error-field',
				},
				'whatsapp_telefone': {
					'rules': {
						'empty': {'error': 'Informe seu telefone completo'},
						'tel': {'error': 'Número do celular inválido!'}
					},
					'mask': 'tel',
					'classError': 'error-field',
				}
			},
			'send': f => {''
				
				whatsappInstance.send(whatspp_telefone.value, whatspp_email.value, whatspp_nome.value, this.telefone);
				window.location.reload();
			}
		})	
	}

	static openFormulario(telefone){
		this.openListaTelefones();
		this.telefone = telefone;

		if(typeof(container_form_zap) !== 'undefined'){
			container_form_zap.remove()
		}
			
		const containerRenderWhatsapp = document.createElement("div")
		containerRenderWhatsapp.id = 'container_form_zap'
		containerRenderWhatsapp.classList.add('pai_section_dialog')

		let html = `
		<section class="section_dialog hidden" id="form_zap">
			<div onclick="Whatsapp.closeSectionForm()" class="btn_fechar_form_zap"><i class="ics ic-times"></i></div>
			<form action="/contato/limbo" method="post" id="form-contato-whatsapp" name="form-contato-whatsapp" novalidate="novalidate">
				<div class="dialog_wpp">
					<h2>Mais informações</h2>
					<p class="under_p">&nbsp;</p>
					<div class="site-space-2"></div>
					<div class="inputs_wpp">
						<div class="inputs_dados">
							<p>Nome</p>
							<input id="whatspp_nome" type="text" name="whatsapp_nome" placeholder="Nome">
							<div class="site-space-1"></div>
							<p>Telefone</p>
							<input id="whatspp_telefone" type="tel" name="whatsapp_telefone" placeholder="Telefone">
							<div class="site-space-1"></div>
						</div><div class="input_msg">
							<p>E-mail</p>
							<input id="whatspp_email" type="email" name="whatsapp_email" placeholder="E-mail">
							<div class="site-space-1"></div>
							<p>&nbsp;</p>
							<button type="submit" class=send_wpp"><i class="icb ic-whatsapp"></i>&nbsp;&nbsp;ENVIAR</button>
						</div>
					</div>
				</div>
			</form>
		</section>
		`;

		containerRenderWhatsapp.innerHTML = html
		document.body.appendChild(containerRenderWhatsapp)

		Whatsapp.bossValidate();
	}

	static openListaTelefones(){
		whatsapp_lista_telefones.classList.toggle('hidden');
	}

	static openSectionForm(){
		form_zap.classList.remove('hidden');
	}

	static closeSectionForm(){
		form_zap.classList.add('hidden');
	}

    _render(){

        if(this.listaNumerosTelefone.length > 0){

            // Criar o Elemento para renderizar.
            const containerRenderWhatsapp = document.createElement("div")
            containerRenderWhatsapp.id = 'imo_telefones_btn'
            containerRenderWhatsapp.classList.add('imo_telefones_btn')
            document.body.appendChild(containerRenderWhatsapp)
            
            let listaTelefonesHTML = ''
            for(let numeroTelefone in this.listaNumerosTelefone){

                let telefone = this.listaNumerosTelefone[numeroTelefone];

                if(Whatsapp.isDicionario(telefone)){

                    if(typeof(telefone['nome']) !== 'string' || typeof(telefone['numero']) !== 'string'){
                        console.error('Informe na lista de telefones, um nome e número.')
						continue
					}
                    
					listaTelefonesHTML += `<p onclick="Whatsapp.openFormulario(${telefone['numero']}); Whatsapp.openSectionForm()">${telefone['nome']}</p>`;
                }
            }

			if(listaTelefonesHTML != ''){

				let corretores = [];
				corretores.push({
					'nome' : 'Henrique Dall Agnol',
					'img' : '/jnh/corretores/henrique.jpg',
					'wpp' : '(54) 99946-5510',
					'wppstr' : '5554999465510',
				});
				corretores.push({
					'nome' : 'Djonatas de Britto',
					'img' : '/jnh/corretores/djonatas.jpg',
					'wpp' : ' (54) 99191-3313',
					'wppstr' : ' 5554991913313',
				});
				
				let corretores_html = '';

				for(let i in corretores){
					corretores_html += `
						<div class="corretor_btn_wpp">
							<div class="corretor_img">
								<div class="img">
									<img src="${corretores[i]['img']}" alt="Corretor">
								</div>
							</div><div class="corretor_dados">
								<div class="btn_zap">
									<a href="//web.whatsapp.com/send?phone=${corretores[i]['wppstr']}" target="_blank">
										<p class="no-mobile"><i class="icb ic-whatsapp"></i>&nbsp;&nbsp;${corretores[i]['wpp']}</p>
									</a>
									<a href="//web.whatsapp.com/send?phone=${corretores[i]['wppstr']}" target="_blank">
										<p class="no-pc"><i class="icb ic-whatsapp"></i>&nbsp;&nbsp;${corretores[i]['wpp']}</p>
									</a>
								</div>
							</div>
						</div>
					`;
				}

				containerRenderWhatsapp.innerHTML = `
					<div id="wpp_pulsante" onclick="Whatsapp.openListaTelefones()">
						<i class="icb ic-whatsapp"></i>
					</div>
					<div id="whatsapp_lista_telefones" class="hidden">
						${corretores_html}
					</div>
				`;
			}

			let current_URL = window.location.href.split('/');
			if(typeof(current_URL[4]) == 'string' && current_URL[4] == 'ver'){
				containerRenderWhatsapp.innerHTML = '';
			}
        }

        return
    }
}